import React from 'react'

export const RiArrowRightSLine = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.57819 12.0535C10.2388 11.518 10.8266 11.0378 11.4179 10.5617C12.3864 9.78212 13.3694 9.02045 14.3206 8.21955C14.7172 7.8856 15.0509 7.47237 15.4071 7.08925C15.56 6.92477 15.6936 6.71307 15.5122 6.51482C15.3109 6.29463 15.0913 6.43326 14.9232 6.60451C13.1159 8.44754 10.8786 9.71207 8.89286 11.3153C8.30774 11.7878 8.2315 12.1857 8.72693 12.7703C8.88029 12.9577 9.0468 13.1333 9.22514 13.2959C10.5212 14.4362 11.8088 15.5874 13.1305 16.6959C13.5548 17.0517 14.0696 17.2968 14.5533 17.5737C14.6358 17.6209 14.8531 17.6018 14.8659 17.5633C14.9137 17.422 14.966 17.2153 14.8996 17.1128C14.6383 16.71 14.378 16.2866 14.0342 15.9637C13.2043 15.1844 12.3262 14.4593 11.4709 13.7084C10.9679 13.2667 10.4722 12.8162 9.97119 12.372C9.8616 12.275 9.74365 12.1874 9.57819 12.0535Z'
      fill='#8379BD'
    />
  </svg>
)

export const RiArrowLeftSLine = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Icons / Default icons/Arrow down'>
      <path
        d='M14.4218 12.0535C13.7612 11.518 13.1734 11.0378 12.5821 10.5617C11.6136 9.78212 10.6306 9.02045 9.67939 8.21955C9.28281 7.8856 8.94909 7.47237 8.59294 7.08925C8.43997 6.92477 8.30642 6.71307 8.4878 6.51482C8.68908 6.29463 8.90875 6.43326 9.07676 6.60451C10.8841 8.44754 13.1214 9.71207 15.1071 11.3153C15.6923 11.7878 15.7685 12.1857 15.2731 12.7703C15.1197 12.9577 14.9532 13.1333 14.7749 13.2959C13.4788 14.4362 12.1912 15.5874 10.8695 16.6959C10.4452 17.0517 9.9304 17.2968 9.44667 17.5737C9.36422 17.6209 9.14695 17.6018 9.13406 17.5633C9.0863 17.422 9.03401 17.2153 9.10038 17.1128C9.36167 16.71 9.622 16.2866 9.96581 15.9637C10.7957 15.1844 11.6738 14.4593 12.5291 13.7084C13.0321 13.2667 13.5278 12.8162 14.0288 12.372C14.1384 12.275 14.2563 12.1874 14.4218 12.0535Z'
        fill='#8379BD'
      />
    </g>
  </svg>
)


export const RiArrowDown = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons / Default icons/Another icon">
        <path
          id="another-icon-path"
          d="M11.9465 14.4199C12.482 13.7593 12.9622 13.1715 13.4383 12.5801C14.2179 11.6116 14.9795 10.6287 15.7805 9.67744C16.1144 9.28086 16.5276 8.94714 16.9107 8.59099C17.0752 8.43802 17.2869 8.30447 17.4852 8.48585C17.7054 8.68712 17.5667 8.90679 17.3955 9.0748C15.5525 10.8821 14.2879 13.1195 12.6847 15.1052C12.2122 15.6903 11.8143 15.7665 11.2297 15.2711C11.0423 15.1178 10.8667 14.9512 10.7041 14.7729C9.56382 13.4768 8.41257 12.1893 7.30414 10.8676C6.94825 10.4432 6.7032 9.92845 6.42626 9.44472C6.37912 9.36227 6.3982 9.145 6.43669 9.1321C6.57802 9.08435 6.78474 9.03205 6.88717 9.09843C7.29002 9.35971 7.71344 9.62005 8.0363 9.96386C8.81559 10.7937 9.54065 11.6719 10.2916 12.5271C10.7333 13.0302 11.1838 13.5259 11.628 14.0269C11.725 14.1364 11.8126 14.2544 11.9465 14.4199Z"
          fill="#8379BD"
        />
      </g>
    </svg>
  )
